import React from 'react';
import {graphql, Link} from "gatsby";
import styled from 'styled-components';
import Img from "gatsby-image";

const ProjectWrapper = styled.div`
  width: 100%;
  padding: var(--spacing-xxl);
  background: #f9f9f9;
`;
const ProjectItemsContainer = styled.div`
  width: 100%;
  background: #FFF;
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    padding: var(--spacing-m);  
    flex-direction: column;
  }
`;

const ProjectContent = styled.div`
    flex-basis: calc(100% / 3);
    margin: 0;
    padding: var(--spacing-l);
    color: #202020;
    font-size: 1rem;
    height: 100%;
    min-height: 100%;
    
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        flex-basis: 100%;
        
        .title {
            font-size: 1.5rem;
        }
    }
    
    .title {
        margin-bottom: var(--spacing-m);
        font-family: var(--font-large);
        font-size: 2.5rem;
    }   
    
    .description {
        font-family: var(--font-small);
    }
    
    a {
        display: block;
        margin-top: var(--spacing-m);
        font-family: var(--font-large);
        font-weight: 600;
        color: var(--primary-color);
        text-decoration: none;
        
        &:hover {
            text-decoration: underline;
        }
    }
`;

const ProjectItem = styled.div`
    flex-basis: calc(100% / 3);
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    color: #202020;
    font-size: 1rem;
    
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        flex-basis: 100%;
    }
    
    
    .image {
        max-width: 100%;
        object-fit: cover;
    }
`;

export default function SingleProjectPage({ data: { project } }) {
    return <ProjectWrapper>
        <ProjectItemsContainer>
            <ProjectContent>
                <h1 className={'title'}>{project.title}</h1>
                <div className={'description'}>
                    {project.description}
                </div>
                <Link to={'/projecten'} title={'Terug naar projecten'}>Terug naar projecten</Link>
            </ProjectContent>
            {project.images.map((image, imageIndex) => {
                return <ProjectItem key={imageIndex}>
                    <Img fluid={image.asset.fluid} alt={project.title} />
                </ProjectItem>
            })}
        </ProjectItemsContainer>
    </ProjectWrapper>;
}

export const query = graphql`
    query($slug: String!) {
        project: sanityProjects(slug:  {
            current: {eq: $slug}
        }) {
            images {
              asset {
                fluid(maxWidth: 800, maxHeight: 800) {
                    ...GatsbySanityImageFluid
                }
              }
            }
            category {
              id
            }
            description
            id
            title
        }
    }
`;
